<template>
  <div class="settings">
    <screen-header :title="$t('settings.title')" :breadcrumbs="breadcrumbs" :is-loading="isLoading" />
    <screen-container direction="column">
      <screen-grid>
        <screen-card ratio="1-1" :title="$t('settings.section.accountData.title')" display="grid">
          <template v-slot:body>
            <screen-block
              :title="$t('settings.section.accountData.label.firstname')"
              icon="article"
              :has-data="typeof currentUser.firstname === 'string' && currentUser.firstname !== ''"
              :data="currentUser.firstname"
              ratio="1-3"
              :is-loading="isLoading"
              @click="editSimpleField('firstname', 'text', 'input')"
            />

            <screen-block
              :title="$t('settings.section.accountData.label.lastname')"
              icon="article"
              :has-data="typeof currentUser.lastname === 'string' && currentUser.lastname !== ''"
              :data="currentUser.lastname"
              ratio="1-3"
              :is-loading="isLoading"
              @click="editSimpleField('lastname', 'text', 'input')"
            />

            <screen-block
              :title="$t('settings.section.accountData.label.password')"
              icon="lock"
              :has-data="true"
              data="**********"
              ratio="1-3"
              :is-loading="isLoading"
              @click="editPassword('password')"
            />

            <screen-block
              :title="$t('settings.section.accountData.label.locale')"
              icon="language"
              :has-data="typeof currentUser.locale === 'string'"
              :data="currentUser.locale"
              ratio="1-3"
              :is-loading="isLoading"
              @click="editSimpleField('locale', 'text', 'choice', ['fr', 'en'])"
            />
          </template>
        </screen-card>
      </screen-grid>
    </screen-container>
    <modal-simple-field
      v-if="isEditing && editingModal === 'field'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :label="modal.label"
      :obj-key="modal.objKey"
      :parameters="modal.parameters"
      @save="saveSimpleField"
      @closed="closedModal"
    />
    <modal-password
      v-if="isEditing && editingModal === 'password'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :obj-key="modal.objKey"
      @save="savePassword"
      @closed="closedModal"
    />
  </div>
</template>

<script>
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import ModalSimpleField from '@/components/Modal/SimpleField.vue'
import ModalPassword from '@/components/Modal/Password.vue'
import { mapActions, mapState } from 'vuex'
import { notif } from '@/mixins/notification.mixin'

export default {
  name: 'Settings',
  components: {
    ScreenHeader,
    ScreenContainer,
    ScreenGrid,
    ScreenCard,
    ScreenBlock,
    ModalSimpleField,
    ModalPassword,
  },
  mixins: [notif],
  data() {
    return {
      isLoading: false,
      isEditing: false,
      editingModal: '',
      modal: {
        objKey: null,
        data: null,
        label: null,
        parameters: {
          type: 'text',
          mode: 'input',
          required: false,
          number: null,
        },
      },
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.currentUser,
    }),
    breadcrumbs() {
      return [
        {
          label: this.$t('settings.breadcrumb'),
          route: {
            name: 'Settings',
          },
        },
      ]
    },
    modalTitle() {
      return this.$t('common.modal.title.settings')
    },
  },
  methods: {
    ...mapActions({
      updateUser: 'user/updateUser',
      updatePassword: 'user/updatePassword',
    }),
    editSimpleField(objKey, type, mode, options = []) {
      this.modal.objKey = objKey
      this.modal.label = this.$t(`settings.modal.${objKey}`)
      this.modal.data = this.currentUser[objKey]
      this.modal.parameters = {
        type,
        mode,
        options,
      }
      this.isEditing = true
      this.editingModal = 'field'
      this.$nextTick(() => {
        this.$modal.show('modal-simple-field')
      })
    },
    editPassword(objKey) {
      this.modal.objKey = objKey
      this.isEditing = true
      this.editingModal = 'password'
      this.$nextTick(() => {
        this.$modal.show('modal-password')
      })
    },
    saveSimpleField(params) {
      this.isUpdating = true
      this.updateUser({ ...this.currentUser, ...params.objKey })
        .then(() => {
          this.$modal.hide('modal-simple-field')
          this.closedModal()
          this.notificationSuccess('settings')
        })
        .catch(() => this.notificationError())
    },
    savePassword(params) {
      this.isUpdating = true
      this.updatePassword(params.objKey)
        .then(() => {
          this.$modal.hide('modal-password')
          this.closedModal()
          this.notificationSuccess('settings')
        })
        .catch(() => this.notificationError())
    },
    closedModal() {
      this.isEditing = false
      this.isUpdating = false
      this.editingModal = ''
    },
  },
}
</script>
