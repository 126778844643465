<template>
  <modal name="modal-password" :title="title" :has-apply="true" :is-updating="isUpdating" @save="save" @closed="closed">
    <template v-slot:container>
      <ui-input
        class="modal-password__input"
        v-model="password"
        type="password"
        id="password"
        :label="$t('modal.password.label.password')"
        icon="lock"
        :reverse="true"
        :error="$v.password.$error"
      >
        <template v-if="$v.password.$error && !$v.password.required">
          {{ $t('errors.required') }}
        </template>
        <template v-else-if="$v.password.$error && !$v.password.minLength">
          {{
            $t('errors.minLength', {
              minLength: $v.password.$params.minLength.min,
            })
          }}
        </template>
      </ui-input>
      <ui-input
        v-model="passwordConfirmation"
        type="password"
        id="password-confirm"
        :label="$t('modal.password.label.passwordConfirmation')"
        icon="lock"
        :reverse="true"
        :error="$v.passwordConfirmation.$error"
      >
        <template v-if="$v.passwordConfirmation.$error && !$v.passwordConfirmation.sameAs">
          {{ $t('errors.sameAs') }}
        </template>
      </ui-input>
    </template>
  </modal>
</template>

<script>
import UiInput from '@/components/UI/Input.vue'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'ModalPassword',
  components: {
    UiInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    objKey: {
      type: String,
      required: true,
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      password: '',
      passwordConfirmation: '',
    }
  },
  methods: {
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('save', {
          objKey: { [this.objKey]: this.password },
        })
        this.$v.$reset()
      }
    },
    closed() {
      this.$emit('closed')
    },
  },
  validations() {
    return {
      password: {
        required,
        minLength: minLength(6),
      },
      passwordConfirmation: {
        sameAs: sameAs('password'),
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-password {
  &__input {
    margin-bottom: $gutter-tablet;

    @media (min-width: $screen-xl) {
      margin-bottom: $gutter-desktop;
    }
  }
}
</style>
